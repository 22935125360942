import React from 'react';
import { DashboardCardTitle, convertValueForDashboardDisplay } from '..';
import { computeFAR } from '../../../utilities/scoutHelpers';

// To Do: color values by viability

const ProductFitCard = ({ property: {propertyDetails }}) => {
    const { PropertyGeometry, PropertyStructure, Zone } = propertyDetails;

    const propertyWidth = convertValueForDashboardDisplay(PropertyGeometry.vendor_lot_width_ft, Math.floor, '', '', false);
    const propertyDepth = convertValueForDashboardDisplay(PropertyGeometry.vendor_lot_depth_ft, Math.floor, '', '', false);
    const propertySF = convertValueForDashboardDisplay(PropertyGeometry.lot_size_sq_ft, Math.floor, '', '', false);
    const buildingSF = convertValueForDashboardDisplay(PropertyStructure.building_sq_ft, Math.ceil, '', '', false);

    const maxValueLength = Math.min(9, Math.max(propertyWidth.length, propertySF.length, buildingSF.length, Zone.name.length));
    const valueTDWidth = `${maxValueLength}em`;

    const estimatedFAR = computeFAR(PropertyGeometry.lot_size_sq_ft);

    const renderRow = (label, value) => (
        <tr>
            <td>{label}</td>
            <td style={{ width: valueTDWidth }}>
                <span style={{ fontWeight: 500 }}>{value}</span>
            </td>
        </tr>
    );

    return (
        <div>
            <DashboardCardTitle text="Product Fit" />
            <table className="w-100">
                <tbody>
                    {renderRow('Lot Width', propertyWidth)}
                    {renderRow('Lot Depth', propertyDepth)}
                    {renderRow('Lot SF', propertySF)}
                    {renderRow('Building SF', buildingSF)}
                </tbody>
            </table>
            <table className="mt-2 w-100">
                <tbody>
                    {renderRow('Zoning', Zone.name)}
                    {renderRow('Est. FAR at Sale', estimatedFAR)}
                </tbody>
            </table>
        </div>
    );
};

export default ProductFitCard;
