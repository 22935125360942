const { Amplify } = window.aws_amplify_core;

//Configration of amplify
export function setAmplifyConfigration() {
    Amplify.configure({
        Auth: {
            identityPoolId: 'us-east-1:c44650fc-5504-4f8a-b114-85439b95fd08',
            region: 'us-east-1',
        },
    
        geo: {
            AmazonLocationService: {
                maps: {
                    items: {
                        'pi-map-satellite': {
                            style: 'Default style',
                        },
                        'pi-map-street': {
                            style: 'VectorEsriStreets',
                        },
                    },
                    default: 'pi-map-satellite',
                },
                region: 'us-east-1',
            },
        },
    });
}
