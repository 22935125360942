export const constants = {
    Development: {
        baseUrl: 'https://dev.api.pi.aro.homes',
    },
    Staging: {
        baseUrl: 'https://staging.api.pi.aro.homes',
    },
    Production: {
        baseUrl: 'https://prod.api.pi.aro.homes',
    },
};

const Environment = 'Development';

export function GET_ENVIRONMENT() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return 'Development';
    }

    return Environment;
}

export const getBaseURL = () => {
    let env = GET_ENVIRONMENT();
    return `${constants[env].baseUrl}`;
};
