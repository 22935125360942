export function swapDict(dict) {
    let newDict = {};

    for (const [key, value] of Object.entries(dict)) {
        newDict[value] = key;
    }

    return newDict;
}

export function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}

export function simpleObjectCompare(o1, o2) {
    for (let p in o1) {
        if (Object.prototype.hasOwnProperty.call(o1, p)) {
            if (o1[p] !== o2[p]) {
                return false;
            }
        }
    }
    for (let p in o2) {
        if (Object.prototype.hasOwnProperty.call(o2, p)) {
            if (o1[p] !== o2[p]) {
                return false;
            }
        }
    }
    return true;
}
