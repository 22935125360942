import React from 'react';
import { Auth } from 'aws-amplify';
import { useAppSelector } from '../../store/hooks';
import { selectUserEmail } from '../../store/slices/authSlice';
import { UserProfileIcon } from '../../icons';
import './userProfileMenu.css';

function UserProfileMenu() {
    const userEmail = useAppSelector(selectUserEmail);

    async function handleSignOut() {
        try {
            await Auth.signOut();
            localStorage.clear();
            window.location.href = '/';
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <div className="btn-group dropup">
            <button
                type="button "
                className="btn btn-secondary dropdown-toggle userDropDown"
                id="profileDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <UserProfileIcon />
            </button>
            <ul className="dropdown-menu">
                <li>
                    <div className="dropdown-item">
                        <b>{userEmail}</b>
                    </div>
                </li>
                <li>
                    <hr className="dropdown-divider" />
                </li>
                <li>
                    <button
                        className="dropdown-item"
                        type="button"
                        onClick={handleSignOut}
                    >
            Sign out
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default UserProfileMenu;
