import React, { useEffect } from 'react';
import { doesLotFit, LotFits } from '../../../utilities/scoutHelpers';
import './cards.css';
import useMap, { MapControls, MapContainerSize } from '../../../hooks/useMap';
import {colors} from '../../../constants';

const MapCard = ({ property: {propertyDetails }}) => {
    const { createMap, addMarkers, map } = useMap();
    const lotFits = doesLotFit(propertyDetails);
    const borderClass = lotFits===LotFits.unknown ? 'viabilityUnknownBorder' :
        lotFits===LotFits.true ? 'viabilityOKBorder' : 'viabilityFailBorder';

    useEffect(() => {
        async function createScoutMap(lat, lon) {
            await createMap({
                containerId: 'scoutMap',
                center: [lon, lat],
                zoom: 18,
                controls: [MapControls.ToggleView],
                containerSize: MapContainerSize.Small
            });
        }
        if (!propertyDetails) return;
        const { PropertyGeometry } = propertyDetails;
        let lat = PropertyGeometry.latitude;
        let lon = PropertyGeometry.longitude;
        createScoutMap(lat, lon);     
    }, [propertyDetails]);

    useEffect(() => {
        if (map) {
            const { PropertyGeometry } = propertyDetails;
            addMarkers( [{
                color: colors.skyfall,
                longitude: Number( PropertyGeometry.longitude),
                latitude: Number(PropertyGeometry.latitude),
            }], true);
        }     
    }, [map, propertyDetails]);

    return <div className={`h-100 rounded shadow-sm ${borderClass}`} id="scoutMap" />;
};

export default MapCard;
