import React from 'react';
import './pill.css';

export const PillBackgroundColors = {
    LinenDark: 'linen-dark',
};

const Pill = ({ label, background, compact = false }) => {
    const backgroundClass =
    background === PillBackgroundColors.LinenDark
        ? 'pill-linen-dark'
        : 'pill-linen';
    const paddingClass = compact ? 'pill-compact' : '';
    return (
        <div className={`pill ${backgroundClass} ${paddingClass}`}>{label}</div>
    );
};

export default Pill;
