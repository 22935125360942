import React, { useRef, useState } from 'react';
import { CancelCircle, SearchIcon } from '../../icons';
import './searchField.css';

function SearchField({ onSearch }) {
    const inputReference = useRef(null);
    const [searchValue, setSearchValue] = useState('');

    const handleSearchChange = (ev) => setSearchValue(ev.target.value);

    const handleSearch = () => onSearch(searchValue);

    const handleClear = () => {
        setSearchValue('');
        inputReference.current.focus();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSearch(searchValue);
        }
    };

    const showClearClass = searchValue.length ? 'd-block' : 'd-none';

    return (
        <div className="input-group">
            <input
                type="text"
                className="form-control"
                placeholder="Search for address"
                aria-label="Search for Address"
                value={searchValue}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                ref={inputReference}
                aria-describedby="search-button"
            />
            <button className={`inner-btn ${showClearClass}`} onClick={handleClear}>
                <CancelCircle />
            </button>
            <button
                className="btn btn-primary"
                type="button"
                id="search-button"
                onClick={handleSearch}
            >
                <SearchIcon />
            </button>
        </div>
    );
}

export default SearchField;
