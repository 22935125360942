import React, { useMemo, useState } from 'react';
import DropDownSelect, { DropDownColorTheme } from '../../dropDownSelect';
import { DashboardCardTitle } from '..';
import { PropertyScoutFieldNames } from '../../scout/scout';
import { colors } from '../../../constants';
import { FileIcon } from '../../../icons';

const SourceAndStatusCard = ({ property: { propertyDetails }, onUpdate }) => {
    const {
        init: { valid_sources, valid_statuses },
        PropertyScoutFields,
    } = propertyDetails;
    const [selectedStatus, setSelectedStatus] = useState(
        valid_statuses[PropertyScoutFields.status] || 'None'
    );
    const [selectedSource, setSelectedSource] = useState(
        valid_sources[PropertyScoutFields.source] || 'None'
    );
    const validSources = useMemo(
        () => Object.values(valid_sources),
        [valid_sources]
    );

    const validStatuses = useMemo(
        () => Object.values(valid_statuses),
        [valid_statuses]
    );

    const sharepointLink = useMemo(() => {
        let houseNum = propertyDetails.PropertyAddress.house_number;
        let street = propertyDetails.Street.street_name;
        street = street.replace(/ /g, '-');
        let id = propertyDetails.Property.id;
        let city = propertyDetails.City.name;

        let baseName = `${city}/${houseNum}-${street}_${id}`;
        let name = encodeURI(baseName);

        return (
            'https://vestabuild.sharepoint.com/sites/HouseProjects/Properties/Feasibility/' +
      name
        );
    }, [propertyDetails]);

    const handleSelectStatus = (selected) => {
        const statusId = Object.keys(valid_statuses).find(
            (key) => valid_statuses[key] === selected
        );
        onUpdate(PropertyScoutFieldNames.Status, Number(statusId));
        setSelectedStatus(selected);
    };

    const handleSelectSource = (selected) => {
        const sourceId = Object.keys(valid_sources).find(
            (key) => valid_sources[key] === selected
        );
        onUpdate(PropertyScoutFieldNames.Source, Number(sourceId));
        setSelectedSource(selected);
    };
    return (
        <div>
            <DashboardCardTitle text="Source & Status" />
            <div className="mt-3 w-100">
                <div className="d-flex w-100 justify-content-between">
                    <div>Source</div>
                    <div className="pe-1 w-50">
                        <DropDownSelect
                            options={validSources}
                            selected={selectedSource}
                            onSelect={handleSelectSource}
                            colorTheme={DropDownColorTheme.dark}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-3 w-100">
                <div className="d-flex w-100 justify-content-between">
                    <div>Current Status</div>
                    <div className="pe-1 w-50">
                        <DropDownSelect
                            options={validStatuses}
                            selected={selectedStatus}
                            onSelect={handleSelectStatus}
                            colorTheme={DropDownColorTheme.dark}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-3 w-100">
                <div className="d-flex w-100 justify-content-between">
                    <div className="pe-4">
                        <a
                            className="sharepointLink d-flex align-items-center gap-1"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={sharepointLink}
                        >
                            <FileIcon color={colors.skyfallDark} />
                            <span>Sharepoint Link</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SourceAndStatusCard;
