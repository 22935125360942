const MAX_RESULTS = 200;

const stateAbbr = 'CA'; // To Do: this should be configurable

export const MapQueryType = {
    City: 'city',
    Search: 'search',
    Bounds: 'bounds',
};

export const buildMapQueryString = (query) => {
    let queryString;
    const { type, city, status, search, bounds } = query;

    switch (type) {
    case MapQueryType.City:
        queryString = `max-result=${MAX_RESULTS}`;
        if (city) {
            queryString += `&state=${stateAbbr}&city=${city}`;
        }
        if (status) {
            queryString += `&status=${status}`;
        }
        return queryString;

    case MapQueryType.Search:
        queryString = `search=${search}`;
        if (city) {
            queryString += `&state=${stateAbbr}&city=${city}`;
        }
        return queryString;

    case MapQueryType.Bounds: {
        const { minLat, maxLat, minLon, maxLon } = bounds;
        queryString = `min-lat=${minLat}&max-lat=${maxLat}&min-lon=${minLon}&max-lon=${maxLon}&max-result=${MAX_RESULTS}`;
        if (city) {
            queryString += `&state=${stateAbbr}&city=${city}`;
        }
        if (status) {
            queryString += `&status=${status}`;
        }
        return queryString;
    }
    }
    return null;
};
