import React, { useState } from 'react';

import ViabilityFieldContainer from './viabilityFieldContainer';
import {
    ValidationValues,
    ValidationFields,
    ViabilityFields,
} from '../../constants';
import './scoutViability.css';

const SaveButton = ({ disabled, onSave }) => (
    <div className="col-lg-3 col-md-2 col-sm-12 col-xs-12 h-100 mb-3 d-flex flex-column">
        <div className="scoutViabilityFieldLabel mb-1" />
        <div className="w-100 flex-grow-1 d-flex justify-content-center align-items-center">
            <button
                className="btn btn-primary w-75 h-75 p-2 fs-4 rounded-pill"
                disabled={disabled}
                onClick={onSave}
            >
        Save Changes
            </button>
        </div>
    </div>
);

const ScoutViability = ({
    property: { propertyDetails },
    onScoutValidationFieldsUpdate,
}) => {
    const { PropertyScoutFields } = propertyDetails;
    const validation_fields = PropertyScoutFields.validation_fields || {};

    const [values, setValues] = useState(() =>
        ValidationFields.reduce((acc, fld) => {
            const notes = validation_fields[fld.key]
                ? validation_fields[fld.key][ViabilityFields.Notes] || ''
                : '';
            const costEstimate = validation_fields[fld.key]
                ? // eslint-disable-next-line no-prototype-builtins
                validation_fields[fld.key].hasOwnProperty(
                    ViabilityFields.CostEstimate
                )
                    ? validation_fields[fld.key][ViabilityFields.CostEstimate]
                    : undefined
                : undefined;
            const pass = validation_fields[fld.key]
                ? validation_fields[fld.key][ViabilityFields.Pass] ||
          ValidationValues.NA
                : ValidationValues.NA;
            return {
                ...acc,
                [fld.key]: {
                    notes,
                    costEstimate,
                    pass,
                },
            };
        }, {})
    );
    const [isChanged, setIsChanged] = useState(false);

    const handleChange = (fieldName, valueType, value) => {
        setValues((vals) => {
            const newVals = { ...vals[fieldName] };
            newVals[valueType] = value;
            return { ...vals, [fieldName]: newVals };
        });
        setIsChanged(true);
    };

    const handleSave = async (ev) => {
        ev.preventDefault();
        onScoutValidationFieldsUpdate(values);
    };

    return (
        <div className="row gx-sm-3 gy-0 scoutViabilityRow">
            {ValidationFields.map((field) => (
                <ViabilityFieldContainer
                    key={field.key}
                    viabilityField={field}
                    values={values[field.key]}
                    onChange={handleChange}
                />
            ))}
            <SaveButton onSave={handleSave} disabled={!isChanged} />
        </div>
    );
};

export default ScoutViability;
