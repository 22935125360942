import React, { useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';

import { colors } from '../../../constants';
import '../scoutComparables.css';
import useMap, { MapControls, MapContainerSize } from '../../../hooks/useMap';

function comparablesMarkerPopup(comp) {
    let markerPopupWrapper = document.createElement('div');
    markerPopupWrapper.classList.add('popupWrapper');
    let popupInnerWrapper = document.createElement('div');
    popupInnerWrapper.classList.add('popupInnerWrapper');
    let scoutAddress = document.createElement('div');
    scoutAddress.innerHTML = `<b>${comp.address}</b> `;

    popupInnerWrapper.appendChild(scoutAddress);
    markerPopupWrapper.appendChild(popupInnerWrapper);
    return markerPopupWrapper;
}

const ComparablesMap = ({ comps, keyId, selected, propertyLocation }) => {
    const { createMap, addMarkers, map } = useMap();
    const [selectedPopup, setSelectedPopup] = useState(null);

    useEffect(() => {
        async function createScoutMap(lat, lon, boundingRect) {
            let map = await createMap({
                containerId: `comparablesMap-${keyId}`,
                center: [lon, lat],
                zoom: 12,
                controls: [MapControls.ToggleView],
                containerSize: MapContainerSize.Small,
            });
            map.fitBounds([
                [boundingRect.minLon, boundingRect.minLat],
                [boundingRect.maxLon, boundingRect.maxLat],
            ]);
        }

        if (!comps || !comps?.length) {
            return;
        }
        // Western/Northern hemisphere only.
        const lats = comps
            .map((comp) => Number(comp.latitude))
            .filter((lat) => !isNaN(lat));
        const lons = comps
            .map((comp) => Number(comp.longitude))
            .filter((lon) => !isNaN(lon));
        lats.push(propertyLocation.latitude);
        lons.push(propertyLocation.longitude);
        const boundingRectangle = {
            minLat: Math.min(...lats),
            maxLat: Math.max(...lats) + 0.01, // so top markers are visible
            minLon: Math.min(...lons),
            maxLon: Math.max(...lons),
        };
        const centerLat = (boundingRectangle.minLat + boundingRectangle.maxLat) / 2;
        const centerLon = (boundingRectangle.minLon + boundingRectangle.maxLon) / 2;
        createScoutMap(centerLat, centerLon, boundingRectangle);
    }, []);

    useEffect(() => {
        if (map && comps) {
            const markerLocations = comps.map((property) => ({
                color: colors.goldwood,
                longitude: Number(property.longitude),
                latitude: Number(property.latitude),
                popUpWrapper: comparablesMarkerPopup(property),
            }));
            markerLocations.push({
                color: colors.skyfall,
                longitude: Number(propertyLocation.longitude),
                latitude: Number(propertyLocation.latitude),
            });
            addMarkers(markerLocations, true);
            if (selectedPopup) {
                clearPopUp(selectedPopup);
            }
        }
    }, [map, comps]);

    useEffect(() => {
        if (selected) {
            if (selectedPopup) {
                clearPopUp(selectedPopup);
            }
            let popup = new maplibregl.Popup({
                offset: 22,
                closeButton: false,
            })
                .setLngLat([selected.longitude, selected.latitude])
                .setDOMContent(comparablesMarkerPopup(selected));
            setSelectedPopup(popup);
            popup.addTo(map);
        }
    }, [selected]);

    // To Do: DRY with map?
    function clearPopUp(element) {
        let markerHighlight = document.getElementsByClassName('maplibregl-popup');
        if (markerHighlight.length > 0) {
            markerHighlight[0].style.display = 'none';
        }
        element.remove();
    }

    return (
        <div
            className={'h-100 w-100 rounded shadow-sm'}
            id={`comparablesMap-${keyId}`}
        />
    );
};

export default ComparablesMap;
