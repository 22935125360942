import { getMonthsBetween } from './dateHelpers';

export const CompType = {
    Acquisition: 'acq',
    Sales: 'sales',
};

export const CompTypeKey = {
    acq: 'acqComps',
    sales: 'salesComps',
};

const calculateDistanceScore = (comp) => {
    const { distance } = comp;
    if (distance === null || distance === undefined) {
        return 0;
    }
    return distance <= 0.3 ? 25 : distance <= 0.9 ? 20 : 5;
};

const calculateSaleCompBldSizeScore = (comp) => {
    const { building_sf } = comp;
    if (!building_sf) {
        return 0;
    }
    return building_sf < 1200
        ? 5
        : building_sf <= 1800
            ? 10
            : building_sf <= 2200 || building_sf > 3200
                ? 15
                : building_sf <= 2500 || (building_sf >= 2900 && building_sf < 3200)
                    ? 20
                    : 25;
};

const calculateAcqCompBldSizeScore = (comp, prop) => {
    const { building_sf: comp_sf } = comp;
    const {
        PropertyStructure: { building_sq_ft },
    } = prop;
    if (!comp_sf || !building_sq_ft) {
        return 0;
    }
    const diff = Math.abs(building_sq_ft - comp_sf);

    return diff <= 300
        ? 25
        : diff <= 500
            ? 20
            : diff <= 800
                ? 15
                : diff <= 1100
                    ? 10
                    : 5;
};

const calculateSaleYearBuiltScore = (comp) => {
    const { year_built } = comp;
    if (year_built === null || year_built === undefined) {
        return 0;
    }
    return year_built < 1995
        ? 5
        : year_built < 2010
            ? 10
            : year_built < 2019
                ? 15
                : year_built < 2023
                    ? 20
                    : 25;
};

const calculateAcqYearBuiltScore = (comp, prop) => {
    const { year_built: comp_year } = comp;
    const {
        PropertyStructure: { year_built },
    } = prop;
    if (!year_built || !comp_year) {
        return 0;
    }
    const diff = Math.abs(year_built - comp_year);
    return diff <= 10
        ? 25
        : diff <= 20
            ? 20
            : diff <= 30
                ? 15
                : diff < 40
                    ? 10
                    : 5;
};

const calculateDateSoldScore = (comp) => {
    const { sale_date } = comp;
    if (sale_date === null || sale_date === undefined) {
        return 0;
    }

    const saleDate = new Date(sale_date);
    const today = new Date();
    const diffMonths = getMonthsBetween(saleDate, today);

    return diffMonths <= 3
        ? 25
        : diffMonths <= 6
            ? 20
            : diffMonths <= 12
                ? 15
                : diffMonths <= 18
                    ? 10
                    : 5;
};

export function calculateInitialWeight(comp, prop, compType) {
    if (compType === CompType.Sales) {
        return (
            calculateDistanceScore(comp) +
      calculateSaleCompBldSizeScore(comp) +
      calculateSaleYearBuiltScore(comp) +
      calculateDateSoldScore(comp)
        );
    }
    // Acquisiton Comps
    return (
        calculateDistanceScore(comp) +
    calculateAcqCompBldSizeScore(comp, prop) +
    calculateAcqYearBuiltScore(comp, prop) +
    calculateDateSoldScore(comp)
    );
}
