import React from 'react';
import { ValidationFields, ValidationValues } from '../../../constants';
import { DashboardCardTitle } from '..';
import './cards.css';

const ResultColors = {
    [ValidationValues.Pass]: 'viabilityPassResult',
    [ValidationValues.NoPass]: 'viabilityNoPassResult',
    [ValidationValues.Concerns]: 'viabilityConcernsResult',
    [ValidationValues.NA]: 'viabilityNAResult',
};

const ViabilityResult = ({ label, value }) => {
    const colorClass = ResultColors[value] || 'viabilityNAResult';
    return (
        <div className="w-100 mb-2 d-flex justify-content-between">
            <div>{label}:</div>
            <div className={`fw-bold ${colorClass}`}>{value}</div>
        </div>
    );
};

const ViabilityCard = ({ property: { propertyDetails } }) => {
    const { PropertyScoutFields } = propertyDetails;
    const validation_fields = PropertyScoutFields.validation_fields || {};

    const getViability = (fieldName) =>
        validation_fields[fieldName]
            ? validation_fields[fieldName]?.pass
            : ValidationValues.NA;

    return (
        <div>
            <DashboardCardTitle text="Viability" />
            <div className="row pe-5">
                {ValidationFields.map((field) =>
                    field.isPassFailField ? (
                        <div
                            className="col-lg-4 pe-4 border-end border-primary"
                            key={field.key}
                        >
                            <ViabilityResult
                                label={field.label}
                                value={getViability(field.key)}
                            />
                        </div>
                    ) : null
                )}
            </div>
        </div>
    );
};

export default ViabilityCard;
