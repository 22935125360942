import streetviewIcon from '../../img/streetview.svg';
import satelliteviewIcon from '../../img/satelliteview.svg';
import { MapContainerSize } from '.';

const MapStyle = {
    Satellite: 'pi-map-satellite',
    Street: 'pi-map-street'
};

export default class ToggleViewControl {
    constructor(size='lg') {
        this.eventHandler = this.eventHandler.bind(this);
        this.size = size;
    }

    eventHandler() {
        const newMapStyle = this._mapStyle === 
         MapStyle.Satellite ? MapStyle.Street : MapStyle.Satellite
        ;
        this._map.setStyle(newMapStyle);
        this._btn_image.className = newMapStyle === MapStyle.Street ? 'satelliteviewIcon' :'streetviewIcon';
        this._btn_image.src = newMapStyle === MapStyle.Street ? satelliteviewIcon : streetviewIcon;
        this._map.resize();
        this._mapStyle = newMapStyle;
    }

    onAdd(map) {
        this._map = map;
        this._mapStyle = MapStyle.Satellite;
        this._btn = document.createElement('button');
        this._btn_image = document.createElement('img');
        this._btn.type = 'button';
        this._btn.style.borderRadius = '20%';
        if (this.size === MapContainerSize.Small) {
            this._btn.style.width='22px';
            this._btn.style.height='22px';
            this._btn.style.marginTop='-2px';
        }
        this._btn_image.className = 'streetviewIcon';
        this._btn_image.src = streetviewIcon;
        this._btn_image.style.width = '70%';
        this._btn_image.style.height = '70%';
        this._btn.onclick = this.eventHandler;
        this._btn.appendChild(this._btn_image);
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
        this._container.appendChild(this._btn);
        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}
