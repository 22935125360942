import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { colors } from '../../constants';

const EditableCurrency = ({ initialValue, placeHolder, id, onSave }) => {
    const [value, setValue] = useState(initialValue || '');
    const [hasChanged, setHasChanged] = useState(false);
    const handleChange = (value) => {
        // note: see react-currency-input-field for other available params
        setHasChanged(true);
        setValue(value);
    };

    const handleSave = () => {
        onSave(value);
        setHasChanged(false);
    };

    const handleCancel = () => {
        setValue(initialValue || '');
        setHasChanged(false);
    };

    return (
        <div className="w-100 d-flex  align-items-center">
            <CurrencyInput
                value={value}
                id={id}
                name={id}
                placeholder={placeHolder.padEnd(22,' ')}
                allowDecimals={false}
                allowNegativeValue={false}
                onValueChange={handleChange}
                prefix="$"
                style={{textAlign:'right', padding:'0 4px'}}
            />
            <button className={`btn p-0 ms-2 ${hasChanged ? 'visible' : 'invisible'}`}
                disabled={!hasChanged} onClick={handleSave}>
                <span style={{color: colors.aroGreen, lineHeight:'18px', fontSize:'18px', fontWeight:600}}>&#10003;</span>
            </button>
            <button className={`btn p-0 ms-2 ${hasChanged ? 'visible' : 'invisible'}`} disabled={!hasChanged} onClick={handleCancel}>
                <span style={{lineHeight:'14px', fontSize:'14px', fontWeight:500}}>X</span>
            </button>
        </div>
    );
};

export default EditableCurrency;
