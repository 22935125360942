import React, { useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import Page from '../page';
import MapHeader from '../mapHeader';
import MapSidebar from '../mapSidebar/mapSidebar';
import { markerPopUp } from '../library/markerPopUp';
import Loader from '../loader/loader';
import './map.css';
import { useAppSelector } from '../../store/hooks';
import { selectAuthToken } from '../../store/slices/authSlice';
import useGetMapProperties from '../../hooks/useGetMapProperties';
import useMap, { MapControls } from '../../hooks/useMap';
import { useGetMapInitDataQuery } from '../../store/slices/apiSlice/map-api-slice';
import { colors } from '../../constants';

function Map() {
    const token = useAppSelector(selectAuthToken);
    const [selectedCity, setSelectedCity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState(null);
    const [sideBarProperties, setSideBarProperties] = useState(null);
    const [mapMoveEvent, setMapMoveEvent] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [sideBarPopup, setSideBarPopup] = useState(null);
    // See note below on why this is here
    const [mapHasMoved, setMapHasMoved] = React.useState(false);

    const { data: mapInitData } = useGetMapInitDataQuery();
    const { createMap, addMarkers, map } = useMap();
    const {
        queryByCity,
        queryBySearch,
        queryByBounds,
        /*isError, */ mapBounds,
        mapProperties,
    } = useGetMapProperties();

    function setMapEvents(newMap) {
        newMap.on('moveend', function (e) {
            setMapMoveEvent(e);
        });
    }

    useEffect(() => {
        if (mapMoveEvent == null) return;

        if (map == null) return;

        if (searchValue != null && searchValue != '') return;

        // Why is this here?
        // When the map is set up, it has generic "Bay Area" boundaries
        // After the initial load, there's a request to get data for the map
        // When the data comes back, it sets the new boundaries.
        // Setting the new boundaries triggers a Map Moved event, which then
        // makes a second request for API data. This is not desired behavior,
        // as during the time between the first API request and second API request
        // they may have started filtering the map, or doing other things.
        // This is a bit of a hack, would love to find a cleaner way to solve this.
        if (mapHasMoved == false) {
            setMapHasMoved(true);
            return;
        }

        let { _sw, _ne } = map.getBounds();
        queryByBounds(
            { minLat: _sw.lat, maxLat: _ne.lat, minLon: _sw.lng, maxLon: _ne.lng },
            selectedCity,
            selectedStatus
        );
    }, [mapMoveEvent]);

    useEffect(() => {
        async function createMainMap() {
            let newMap = await createMap({
                containerId: 'map',
                center: [-120.0715, 37.3906],
                zoom: 6,
                controls: [
                    MapControls.Nav,
                    MapControls.SelfLocation,
                    MapControls.ToggleView,
                ],
            });
            setMapEvents(newMap);
        }

        if (token) {
            createMainMap();
        }
    }, [token]);

    useEffect(() => {
        let div = document.getElementById('map-wrap');

        if (loading) {
            div.style.opacity = 0.3;
        } else {
            div.style.opacity = 1;
        }
    }, [loading]);

    useEffect(() => {
        if (token == '') return;

        if (loading) return;

        setLoading(true);
        refreshMap();
    }, [selectedCity]);

    useEffect(() => {
        if (searchValue == null) return;
        setLoading(true);
        if (searchValue === '') {
            queryByCity(selectedCity, selectedStatus);
        } else {
            queryBySearch(searchValue, selectedCity);
        }
    }, [searchValue]);

    useEffect(() => {
        if (mapInitData) {
            queryByCity('', selectedStatus);
        }
    }, [mapInitData]);

    React.useEffect(() => {
        if (mapBounds && mapBounds.length) {
            map.fitBounds(mapBounds);
        }
    }, [mapBounds]);

    useEffect(() => {
        if (!mapProperties) {
            return;
        }
        createMarkers(mapProperties);

        // Get the top 20 elements in the list
        let sortedArray = mapProperties.slice(0, 20).map((element) => element);
        setSideBarProperties(sortedArray);
        setLoading(false);
    }, [mapProperties]);

    function refreshMap() {
    // TODO: this seems like a hack
        setMapMoveEvent(Date.now());
    }

    useEffect(() => {
        if (selectedStatus == null && map == null) return;

        setLoading(true);
        refreshMap();
    }, [selectedStatus]);

    function createMarkers(locations) {
        const markerLocations = locations.map((property, index) => ({
            color: index < 10 ? colors.goldwood : colors.skyfall,
            longitude: Number(property.longitude),
            latitude: Number(property.latitude),
            popUpWrapper: markerPopUp(property),
        }));
        addMarkers(markerLocations, true);
    }

    function clearPopUp(element) {
        let markerHighlight = document.getElementsByClassName('maplibregl-popup');

        if (markerHighlight.length > 0) {
            markerHighlight[0].style.display = 'none';
        }

        element.remove();
    }

    useEffect(() => {
        if (sideBarPopup == null) return;

        sideBarPopup.addTo(map);
    }, [sideBarPopup]);

    function popupOnSideClick(targetId) {
        if (sideBarPopup != null) clearPopUp(sideBarPopup);

        let element = sideBarProperties[targetId];

        // To Do: Dry this with useMap....
        let markerPopupWrapper = markerPopUp(element);
        let popup = new maplibregl.Popup({
            offset: 22,
            closeButton: false,
        })
            .setLngLat([element.longitude, element.latitude])
            .setDOMContent(markerPopupWrapper);

        setSideBarPopup(popup);
    }

    return (
        <Page>
            <div className="min-vh-100 pb-2 d-flex flex-column">
                <MapHeader
                    selectedCity={selectedCity}
                    selectedStatus={selectedStatus}
                    onSearch={setSearchValue}
                    onSelectCity={setSelectedCity}
                    onSelectStatus={setSelectedStatus}
                />
                <div className="ps-0 ps-md-4 mt-2" id="map-wrap">
                    <div className="row gx-0">
                        <div className="col-12 col-md-9 col-xl-10">
                            <div className="map rounded-3" id="map" />
                        </div>
                        <div className="col-12 col-md-3 col-xl-2">
                            {!(sideBarProperties == null || sideBarProperties.length == 0) ? (
                                <MapSidebar
                                    properties={sideBarProperties}
                                    onPropertyClick={popupOnSideClick}
                                />
                            ) : (
                                <div className="fw-bold px-3">
                                    {loading ? 'Loading Properties...' : 'No Records Found.'}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {loading && <Loader />}
        </Page>
    );
}

export default Map;
