import React, { useMemo } from 'react';
import StaticDropDown from '../../staticDropDown';
import { DashboardCardTitle, Dashboard_Not_Available_Label } from '..';
import './cards.css';

const isHighSchool = (school) => school.max_assigned_grade === '12';
const isElementarySchool = (school) =>
    school.max_assigned_grade <= 5 || school.name.includes('Elementary');

const CharacteristicsCard = ({ property: { propertyDetails } }) => {
    const { PropertyAddress, AssignedSchool } = propertyDetails;

    const highSchools = useMemo(
        () =>
            AssignedSchool.filter((school) => isHighSchool(school)).map(
                (school) => school.name
            ),
        [AssignedSchool]
    );

    const elementarySchools = useMemo(
        () =>
            AssignedSchool.filter((school) => isElementarySchool(school)).map(
                (school) => school.name
            ),
        [AssignedSchool]
    );

    const renderRow = (label, value) => (
        <>
            <div style={{ wordWrap: 'break-word', hyphens: 'manual' }}>{label}</div>
            <div style={{ fontWeight: 500, wordWrap: 'break-word' }}>{value}</div>
        </>
    );

    const renderSchoolsRow = (label, schools, keyPrefix) => {
        if (!schools.length) {
            return renderRow(label, Dashboard_Not_Available_Label);
        } else if (schools.length === 1) {
            return renderRow(label, schools[0]);
        } else
            return (
                <>
                    <div>{label}</div>
                    <div>
                        <StaticDropDown values={schools} keyPrefix={keyPrefix} />
                    </div>
                </>
            );
    };

    // what's going on with the \u00ab? this is unicode for &shy; which
    // allows label to be conditionally wrapped with a hypen
    return (
        <div>
            <DashboardCardTitle text="Characteristics" />
            <div className="pe-1 characteristicsGrid">
                {renderRow(
                    'Neigh\u00adbor\u00adhood',
                    PropertyAddress?.neighborhood_name || Dashboard_Not_Available_Label
                )}
                {renderSchoolsRow('High School', highSchools, 'highschool')}
                {renderSchoolsRow(
                    'Element\u00adary School',
                    elementarySchools,
                    'elementary'
                )}
            </div>
        </div>
    );
};

export default CharacteristicsCard;
