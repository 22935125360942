import { aroApiSlice } from './apiSlice';
import { getBaseURL } from '../../../utilities/config';
import { cleanValidationFields } from '../../../utilities/dataAdapters';
import { buildMapQueryString } from '../../../utilities/mapHelpers';
// To Do: use baseURL and remove full url from query
var sourceParam = null;

export const mapApiSlice = aroApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMapInitData: builder.query({
            query: () => `${getBaseURL()}/mapapi?init=true`,
        }),
        getMapData: builder.query({
            query: (query) => `${getBaseURL()}/mapapi?${buildMapQueryString(query)}`,
        }),
        getKanban: builder.query({
            query: ({ maxResults, city, source }) => {
                sourceParam = null;
                let params = `kanban=true&max-result=${maxResults}`;
                if (city) {
                    params += `&state=CA&city=${city}`;
                }
                if (source) {
                    params += `&source=${source}`;
                    sourceParam = source;
                }
                return `${getBaseURL()}/mapapi?${params}`;
            },
            transformResponse: (response) => {
                const numberSourceParam = Number(sourceParam);
                const records = sourceParam
                    ? response.records.filter((prop) => prop.source === numberSourceParam)
                    : response.records;
                const transformedRecords = records.map((prop) => {
                    return {
                        ...prop,
                        validation_fields: cleanValidationFields(prop?.validation_fields),
                    };
                });
                return { ...response, records: transformedRecords };
            },
        }),
    }),
});

export const {
    useLazyGetKanbanQuery,
    useLazyGetMapDataQuery,
    useGetMapInitDataQuery,
} = mapApiSlice;
