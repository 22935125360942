import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import ComparablesRow from './comparablesRow';
import { colors } from '../../../constants';
import '../scoutComparables.css';

const getWeightsFromComps = (comps) => {
    const wts = {};
    comps.forEach((comp) => (wts[comp.pid] = comp?.weight));
    return wts;
};

const WeightLabel = ({ onCancel, onSave }) => (
    <div className="d-flex flex-column">
        <div>Weight</div>
        <div>
            <button className={'btn p-0'} onClick={onSave}>
                <span
                    style={{
                        color: colors.aroGreen,
                        lineHeight: '18px',
                        fontSize: '18px',
                        fontWeight: 600,
                    }}
                >
          &#10003;
                </span>
            </button>
            <button className={'btn p-0 ms-2'} onClick={onCancel}>
                <span style={{ lineHeight: '14px', fontSize: '14px', fontWeight: 500 }}>
          X
                </span>
            </button>
        </div>
    </div>
);

const ComparablesTable = ({
    comps,
    keyId,
    onSelect,
    onReorder,
    editOK,
    onSave,
    onCancel,
    onRemove,
}) => {
    const [weights, setWeights] = useState(() => getWeightsFromComps(comps));

    useEffect(() => {
        setWeights(getWeightsFromComps(comps));
    }, [comps]);

    const handleDragEnd = (result) => {
        const { destination, source, draggableId } = result;
        if (!destination) {
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
      destination.index === source.index
        ) {
            return;
        }
        onReorder(draggableId, source, destination);
    };

    const handleChangeWeight = (id, value) => {
        const newWeights = { ...weights };
        newWeights[id] = value;
        setWeights(newWeights);
    };

    const handleSave = () => {
        onSave(weights);
    };

    const handleCancel = () => {
        setWeights(getWeightsFromComps(comps));
        onCancel();
    };

    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" className="fw-normal comparableAddress">
              Property Address
                        </th>
                        <th scope="col" className="fw-normal">
              Date Sold
                        </th>
                        <th scope="col" className="fw-normal">
              Distance
                        </th>
                        <th scope="col" className="fw-normal">
              Bld.Size
                        </th>
                        <th scope="col" className="fw-normal">
              Yr Built
                        </th>
                        <th scope="col" className="fw-normal text-center">
              $/SF
                        </th>
                        <th scope="col" className="fw-normal">
              Price Sold
                        </th>
                        <th
                            scope="col"
                            className="fw-normal pe-1 text-center comparableWeight"
                        >
                            {editOK ? (
                                <WeightLabel onSave={handleSave} onCancel={handleCancel} />
                            ) : (
                                'Weight'
                            )}
                        </th>
                        <th scope="col"></th>
                        <th className="spacerColumn" />
                    </tr>
                </thead>

                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId={keyId}>
                        {(provided) => (
                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                {comps.map((prop, index) => (
                                    <ComparablesRow
                                        key={`${keyId}-${prop.pid}`}
                                        prop={{ ...prop, weight: weights[prop.pid] }}
                                        index={index}
                                        onSelect={onSelect}
                                        onChangeWeight={editOK ? handleChangeWeight : null}
                                        onRemove={onRemove}
                                        removeOK={!editOK}
                                    />
                                ))}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </DragDropContext>
            </table>
        </div>
    );
};

export default ComparablesTable;
