import React, { useState } from 'react';
import CompChartPriceByDate from './compChartPriceByDate';
import CompChartPriceByWeight from './compChartPriceByWeight';
import { LineChartIcon, ScatterChartIcon } from '../../../icons';
import { colors } from '../../../constants';

export const ChartType = { Weight: 1, Date: 2 };

const CompsChartSelector = ({ initialChartType, comps, avgSalePrice }) => {
    const [chartType, setChartType] = useState(initialChartType);

    const handleNextChart = () => {
        const nextChart =
      chartType === ChartType.Weight ? ChartType.Date : ChartType.Weight;
        setChartType(nextChart);
    };

    if (!comps.length) {
        return null;
    }

    return (
        <>
            {chartType === ChartType.Weight ? (
                <CompChartPriceByWeight comps={comps} avgSalePrice={avgSalePrice} />
            ) : (
                <CompChartPriceByDate comps={comps} avgSalePrice={avgSalePrice} />
            )}
            <button
                className="btn p-0 position-absolute nextChartButton"
                title="Next Chart"
                onClick={handleNextChart}
            >
                {chartType === ChartType.Weight ? (
                    <LineChartIcon size={14} color={colors.goldwood} />
                ) : (
                    <ScatterChartIcon size={14} color={colors.goldwood} />
                )}
            </button>
        </>
    );
};

export default CompsChartSelector;
