import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import { aroApiSlice } from './slices/apiSlice/apiSlice';
import mapReducer from './slices/mapSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        map: mapReducer,
        [aroApiSlice.reducerPath]: aroApiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            aroApiSlice.middleware
        ),
});
