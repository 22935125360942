import React from 'react';

import MapPropertyCard from '../mapPropertyCard';
import './mapSidebar.css';

function MapSidebar({ properties, onPropertyClick }) {
    const propertyClick = function (event) {
        onPropertyClick(event.currentTarget.id);
    };

    return (
        <div className="map-overlay-property-wrapper mt-3 mt-md-0 ms-3">
            {properties.map((element, index) => (
                <div
                    className="mb-1 mb-md-2"
                    key={index}
                    id={index}
                    onClick={propertyClick}
                >
                    <MapPropertyCard
                        id={element.id}
                        address={element.partial_address}
                        city={element.city_name}
                        estimatedSpread={element.estimated_spread}
                        rank={index + 1}
                    />
                </div>
            ))}
        </div>
    );
}

export default MapSidebar;
