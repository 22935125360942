import React from 'react';

import { DownCaretIcon } from '../../icons';
import './staticDropDown.css';

const StaticDropDown = ({ values, keyPrefix }) => {
    const v1 = values[0];
    const vRest = values.slice(1);

    return (
        <div className={'dropdown'}>
            <button
                type="button"
                className="btn-outline border-0 staticDropDown dropDownButton rounded-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <div className="row">
                    <div className="col-12 text-start ps-1">
                        {v1}
                        <DownCaretIcon />
                    </div>
                </div>
            </button>
            <div
                className="dropdown-menu staticDropDownList"
                aria-labelledby="dropdownMenuLink"
            >
                {vRest.map((option, index) => (
                    <div
                        className="dropdown-item text-start staticDropDownItem"
                        id={option}
                        key={`${keyPrefix}-${index}`}
                    >
                        {option}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StaticDropDown;
