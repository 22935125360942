// For conversion AVM value to dollar
function estimateValueFormatter(value) {
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
    });
    return formatter.format(value);
}

// Pop up division of marker
export function markerPopUp(location) {
    if (localStorage.getItem('id_token') === null) {
        window.location.reload();
    }
    let markerPopupWrapper = document.createElement('div');
    markerPopupWrapper.classList.add('popupWrapper');
    let popupInnerWrapper = document.createElement('div');
    popupInnerWrapper.classList.add('popupInnerWrapper');

    let ScoutButtonWrapper = document.createElement('div');
    ScoutButtonWrapper.classList.add('ScoutButtonWrapper');

    // Display address in pop up
    let scoutAddress = document.createElement('div');
    scoutAddress.innerHTML = `<b>Address: </b> ${location.display_address}`;

    // Neighborhood name
    const neighborhoodName = document.createElement('div');
    neighborhoodName.innerHTML = `<div><b>Neighborhood Name: </b>${location.neighborhood_name}</div>`;

    // Display Lot depth and lot width in pop up
    const popupLoaction = document.createElement('div');
    popupLoaction.innerHTML = `<div><b>Lot depth: </b>${location.vendor_lot_depth_ft}</div><div> <b>Lot width : </b>${location.vendor_lot_width_ft}</div>`;

    // Display lot size on pop up
    const locationLotSize = document.createElement('div');
    locationLotSize.innerHTML = `<b>Lot size SF: </b>${location.lot_size_sq_ft}`;

    // Display building sq ft on pop up
    const locationBuildingSF = document.createElement('div');
    locationBuildingSF.innerHTML = `<b>Building SF: </b>${location.building_sq_ft}`;

    // Display Estimated value in pop up
    const locationEstimatedValue = document.createElement('div');
    locationEstimatedValue.innerHTML = `<b>Estimated value: </b>${
        location.avm == 'None'
            ? 'None'
            : estimateValueFormatter(location.last_estimated_value)
    }`;

    // Display Score in pop up
    const locationScore = document.createElement('div');
    locationScore.innerHTML = `<b>Score: </b>${location.estimated_score}`;

    // Display Score in pop up
    const locationNormalizedScore = document.createElement('div');
    locationNormalizedScore.innerHTML = `<b>Normalized score: </b>${location.normalized_score}`;

    // Display scout button in pop up
    const scoutButton = document.createElement('div');
    scoutButton.innerHTML = `<a target="_blank" href="/property/${location.id}"><svg version="1.1" viewBox="0 0 16 16" width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"> <polyline points="8.25 2.75,2.75 2.75,2.75 13.25,13.25 13.25,13.25 7.75"/> <path d="m13.25 2.75-5.5 5.5m3-6.5h3.5v3.5"/> </svg></a>`;
    scoutButton.classList.add('scotPopUpButton');
    ScoutButtonWrapper.appendChild(scoutButton);

    popupInnerWrapper.appendChild(scoutAddress);
    popupInnerWrapper.appendChild(neighborhoodName);
    popupInnerWrapper.appendChild(popupLoaction);
    popupInnerWrapper.appendChild(locationLotSize);
    popupInnerWrapper.appendChild(locationBuildingSF);
    popupInnerWrapper.appendChild(locationEstimatedValue);
    popupInnerWrapper.appendChild(locationScore);
    popupInnerWrapper.appendChild(locationNormalizedScore);

    markerPopupWrapper.appendChild(popupInnerWrapper);
    markerPopupWrapper.appendChild(ScoutButtonWrapper);

    return markerPopupWrapper;
}
