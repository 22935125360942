import React from 'react';

const AroLogoHouse = ({ size = 75}) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 58 75"
        height={size}
        width={size}
        fill="#231f20"
    >
        <path d="M54.7,13.7,31.5,2.2a4.4,4.4,0,0,0-3.9,0L4.4,13.7A4.2,4.2,0,0,0,2,17.6V51.9a4.4,4.4,0,0,0,4.4,4.4H52.7a4.4,4.4,0,0,0,4.4-4.4V17.6A4.4,4.4,0,0,0,54.7,13.7Zm-8.9-2.8-4,43.4a1.7,1.7,0,0,1-2.7,1.3C31.9,50.9,10.1,37,3,32.2a1.7,1.7,0,0,1,.2-3l40.1-20A1.8,1.8,0,0,1,45.8,10.9Z" />
    </svg>
);

export default AroLogoHouse;
