import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import ScoutButton from '../../scoutButton';
import { formatStreetAddress } from '../../../utilities/scoutHelpers';
import { colors } from '../../../constants';
import { RemoveIcon } from '../../../icons';
import '../scoutComparables.css';

const EditableCell = ({ weight, id, onChange }) => {
    const value = Math.round(weight);

    const handleChange = (ev) => {
        const newValue = Number(ev.target.value);
        onChange(id, newValue);
    };
    return (
        <input
            value={value}
            style={{ maxWidth: '40px', textAlign: 'right' }}
            onChange={handleChange}
        />
    );
};

const ComparablesRow = ({
    prop,
    onSelect,
    onChangeWeight,
    onRemove,
    removeOK,
    index,
}) => {
    const formatSaleDate = (saleDate) => {
        const [yyyy, mm, dd] = saleDate.substring(0, 10).split('-');
        return `${Number(mm).toString()}/${Number(dd).toString()}/${yyyy.slice(
            -2
        )}`;
    };

    const formatDistance = (distance) => {
        const miles = Math.ceil(distance * 10) / 10;
        return isNaN(miles) ? 'Unkown' : `${miles} ${miles > 1 ? 'Miles' : 'Mile'}`;
    };

    const formatBuildingSF = (sf) => {
        const sqft = Number(sf);
        return isNaN(sqft) || sqft === 0 ? 'Unkown' : sqft.toLocaleString();
    };

    const formatPriceSold = (ps) => {
        const price = Number(ps);
        return isNaN(price) || price === 0
            ? 'Unknown'
            : `$${price.toLocaleString()}`;
    };

    const formatSFPrice = (psf) => {
        const price = Number(psf | 0);
        if (!price) {
            return 'Unknown';
        }
        return Math.round(price);
    };

    const formatWeight = (weight) => {
        return weight === null ? '' : `${Math.round(weight)}`;
    };

    const selectComp = () => onSelect(prop);

    return (
        <Draggable draggableId={String(prop.pid)} index={index}>
            {(provided) => (
                <tr
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    onClick={selectComp}
                >
                    <td className="comparableAddress">
                        <div className="w-100 d-flex justify-content-between">
                            <div className="text-capitalize">
                                {formatStreetAddress(prop.address)}
                            </div>
                            <ScoutButton propertyId={prop.pid} compact={true} />
                        </div>
                    </td>
                    <td>{formatSaleDate(prop.sale_date)}</td>
                    <td>{formatDistance(prop.distance)}</td>
                    <td className="text-end">{formatBuildingSF(prop.building_sf)}</td>
                    <td>{prop.year_built || 'Unknown'}</td>
                    <td className="text-end">{formatSFPrice(prop.price_sf)}</td>
                    <td className="text-end">{formatPriceSold(prop.sale_price)}</td>
                    <td className="text-end">
                        {onChangeWeight && Number(prop.sale_price) > 0 ? (
                            <EditableCell
                                weight={prop.weight}
                                id={prop.pid}
                                onChange={onChangeWeight}
                            />
                        ) : (
                            formatWeight(prop.weight)
                        )}
                    </td>
                    <td onClick={removeOK ? () => onRemove(prop.pid) : null}>
                        <RemoveIcon color={removeOK ? colors.charcoal : colors.aroGray} />
                    </td>
                </tr>
            )}
        </Draggable>
    );
};

export default ComparablesRow;
