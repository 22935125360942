import React from 'react';
import { colors } from '../constants';

const CheckIcon = ({ color = colors.charcoal, size = 40 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={`${size}px`}
        viewBox="0 -960 960 960"
        width={`${size}px`}
        fill={color}
    >
        <path d="M379.33-244 154-469.33 201.67-517l177.66 177.67 378.34-378.34L805.33-670l-426 426Z" />
    </svg>
);

export default CheckIcon;
