import React from 'react';
import { AddIcon } from '../../../icons';
import { colors } from '../../../constants';

const AddComparableButton = ({ disabled = false }) => (
    <>
        <button
            type="button"
            className="btn p-0 ms-2 border-0"
            data-bs-toggle="modal"
            data-bs-target="#add-comparable-modal"
            id="add-comparable-modal-label"
            disabled={disabled}
        >
            <AddIcon color={disabled ? colors.aroGray : colors.charcoal} />
        </button>

        <div
            className="modal fade"
            id="add-comparable-modal"
            tabIndex="-1"
            aria-labelledby="add-comparable-modal-label"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content p-5 text-center">
                    <p>Search For A Comparable Property and Add It - Not Available Yet</p>
                </div>
            </div>
        </div>
    </>
);

export default AddComparableButton;
