import React from 'react';

import { colors, ValidationValues } from '../../constants';
import './passfaildropdown.css';
import { DownCaretIcon } from '../../icons';

const PassFailOptions = Object.values(ValidationValues);

const BtnBackgroundColors = {
    [ValidationValues.NA]: colors.aroGray,
    [ValidationValues.Pass]: colors.aroGreen,
    [ValidationValues.Concerns]: colors.aroYellow,
    [ValidationValues.NoPass]: colors.aroRed,
};

const PassFailDropdown = ({ selected, onSelect, name }) => {
    const backgroundColor = BtnBackgroundColors[selected] || colors.aroGray;

    const handleSelect = (event) => {
        onSelect(event.target.id);
    };

    return (
        <div className={'dropdown'}>
            <button
                type="button"
                className={'btn-outline border-0 shadow-sm rounded-2 PFButton'}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ background: backgroundColor }}
            >
                <div className="row">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <span className="PFDropDownLabel">
                            {selected || ValidationValues.NA}
                        </span>
                        <DownCaretIcon color="#fff" />
                    </div>
                </div>
            </button>
            <div
                className="dropdown-menu dropDownSelectMenu"
                aria-labelledby="dropdownMenuLink"
            >
                {PassFailOptions.map((option, index) => (
                    <div
                        className="dropdown-item"
                        style={{
                            display: selected == option ? 'none' : 'block',
                        }}
                        id={option}
                        onClick={handleSelect}
                        key={`${name}-${index}`}
                    >
                        {option}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PassFailDropdown;
