import { useState } from 'react';
import maplibregl from 'maplibre-gl';
import SelfLocationControl from './SelfLocationControl';
import ToggleViewControl from './ToggleViewControl';

const { createMap:amplifyCreateMap } = window.AmplifyMapLibre;

export const MapControls = {
    Nav: 'nav-control',
    SelfLocation: 'self-location-control',
    ToggleView: 'toggle-view-control'
};

export const MapContainerSize = {
    Small: 'small',
    Large: 'large'
};

function useMap() {
    const [map, setMap] = useState();
    const [mapMarkers,setMapMarkers] = useState([]);

    const removeMapMarkers = () => {
        mapMarkers.forEach((element) => {
            element.remove();
        });
        setMapMarkers([]);
    };

    const createMap = async ({containerId, center, zoom, controls, containerSize=MapContainerSize.Large}) => {
        const navControlOptions = {
            visualizePitch: false,
            showCompass: false,
        };
        let newMap = await amplifyCreateMap({
            container: containerId,
            center: center,
            zoom: zoom,
        });

        controls.forEach(control => {
            switch (control) {
            case MapControls.Nav: 
                newMap.addControl(
                    new maplibregl.NavigationControl(navControlOptions),
                    'bottom-right'
                );
                break;
            case MapControls.SelfLocation:
                newMap.addControl(new SelfLocationControl(), 'bottom-right');
                break;
            case MapControls.ToggleView:
                newMap.addControl(new ToggleViewControl(containerSize), 'bottom-right');
                break; 

            }
        });

        setMap(newMap);
        return newMap;
    };

    const addMarkers = (locations, removeCurrentMarkers) => {
        if (removeCurrentMarkers) {
            removeMapMarkers();
        }

        let newMapMarkers = [];
        locations.forEach((location) => {
            let marker = new maplibregl.Marker(
                {color: location.color}
            )
                .setLngLat([location.longitude, location.latitude]);
            if (location?.popUpWrapper) {
                marker.setPopup(
                    new maplibregl.Popup({
                        offset: 22,
                        closeButton: false,
                    }).setDOMContent(location.popUpWrapper)
                );
            }
            marker.addTo(map);
            newMapMarkers.push(marker);
        });

        if (removeCurrentMarkers) {
            setMapMarkers(newMapMarkers);
        }
        else {
            setMapMarkers (markers => [...markers,...newMapMarkers]);
        }
    }; 

    // More methods to consider: removeMap (from DOM), setMarkerPopup
    return {
        createMap, addMarkers, map
    };
}

export default useMap;
