import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import KanbanPropertyCard from '../kanbanPropertyCard';

const KanbanCard = ({ id, title, properties, mlsHistories, sources, onNotFeasible }) => {
    const handleNotFeasible = (propertyId) => onNotFeasible(propertyId, id);

    return (
        <div className="h-100">
            <h2 className="fs-4 text-center">{title}</h2>
            <Droppable droppableId={id}> 
                { (provided)=> (
                    <div className={'bg-white rounded-3 h-100 p-2 d-flex flex-column gap-2 shadow-lg'}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {properties.map((property,index) => { const { id, partial_address, city_name, source, estimated_spread } = property;
                            return (
                                <KanbanPropertyCard
                                    key={id}
                                    id={String(id)}
                                    index={index}
                                    address={partial_address}
                                    city={city_name}
                                    source={sources[source]}
                                    estimatedSpread={estimated_spread}
                                    handleNotFeasible={handleNotFeasible}
                                    mlsHistory={mlsHistories[id]||{}}
                                />
                            );})}
                        {provided.placeholder} 
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default KanbanCard;
