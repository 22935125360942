import React from 'react';

import { Link } from 'react-router-dom';

const ScoutButton = ({
    propertyId,
    btnType = 'btn-primary',
    compact = false,
}) => {
    const paddingStyle = compact ? 'py-0 px-1' : 'p-1';

    // base class fontSize 14px
    const customStyle = { fontSize: '14px', lineHeight: '15px' };
    if (compact) {
        customStyle.maxHeight = '21px';
    }

    return (
        <Link
            target="_blank"
            to={{
                pathname: `/property/${propertyId}`,
            }}
            className={`btn ${btnType} ${paddingStyle} d-flex align-items-center justify-content-center`}
            style={customStyle}
        >
      SCOUT
        </Link>
    );
};

export default ScoutButton;
