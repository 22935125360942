import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
    useGetPropertyQuery,
    useGetSaleCompsQuery,
    useGetAcquisitionCompsQuery,
    useLazyGetPropertyQuery,
    usePutPropertyScoutFieldsMutation,
    usePutPropertyValidationFieldsMutation,
} from '../../store/slices/apiSlice/scout-api-slice';

import Loader from '../loader/loader';
import Page from '../page';
import { toastSuccess } from '../../utilities/toast';
import ScoutHeader from '../scoutHeader';
import {
    CompType,
    CompTypeKey,
    calculateInitialWeight,
} from '../../utilities/compsHelpers';
import { handleBackendError } from '../../utilities/errorHandling';
import ScoutTabs from './scoutTabs';

export const PropertyScoutFieldNames = {
    Status: 'status',
    Source: 'source',
    Starred: 'starred',
    ManualSalePrice: 'manual_sale_price',
    ManualPurchasePrice: 'manual_purchase_price',
    GenericData: 'generic_data',
    ValidationFields: 'validation_fields',
};

function getOrderedComps(comps, prop, compType) {
    const compRevisions =
    prop.PropertyScoutFields?.generic_data[CompTypeKey[compType]]?.comps || [];
    const compRemovals =
    prop.PropertyScoutFields?.generic_data[CompTypeKey[compType]]?.removedIds ||
    [];
    const workComps = [...comps];
    const orderedComp = [];
    let weightValue;

    for (const comp of compRevisions) {
        const id = comp.propertyId;
        const compIndex = workComps.findIndex((c) => c.pid === id);
        if (compIndex >= 0) {
            weightValue =
        comp.weight != null
            ? comp.weight
            : workComps[compIndex].sale_price > 0
                ? calculateInitialWeight(workComps[compIndex], prop, compType)
                : null;
            orderedComp.push({ ...workComps[compIndex], weight: weightValue });
            workComps.splice(compIndex, 1);
        } else {
            console.debug('Ordered comps contain props not being returned in comps');
        }
    }
    for (const comp of workComps) {
        console.debug('Pushing new comps not previously ordered', comp);
        orderedComp.push({
            ...comp,
            weight:
        comp.sale_price > 0
            ? calculateInitialWeight(comp, prop, compType)
            : null,
        });
    }

    const revisedComps = orderedComp.filter(
        (comp) => !compRemovals.includes(comp.pid)
    );
    return revisedComps;
}

function Scout() {
    let params = useParams();
    const [getUpdatedProperty, getUpdatedPropertyResults] =
    useLazyGetPropertyQuery();

    const {
        data: propertyData,
        error: propertyError,
        isLoading: propertyIsLoading,
    } = useGetPropertyQuery(params.id);
    const saleCompsResponse = useGetSaleCompsQuery(params.id);
    const acquisitionCompsResponse = useGetAcquisitionCompsQuery(params.id);

    const [putPropertyScoutFields, putPropertyScoutFieldsResult] =
    usePutPropertyScoutFieldsMutation();
    const [putValidationFields, putValidationFieldsResult] =
    usePutPropertyValidationFieldsMutation();

    const scoutProperty = useMemo(() => {
        const prop = getUpdatedPropertyResults?.data || propertyData;
        const acqComps =
      prop && acquisitionCompsResponse.data?.comps
          ? getOrderedComps(
              acquisitionCompsResponse.data?.comps,
              prop,
              CompType.Acquisition
          )
          : [];
        const saleComps =
      prop && saleCompsResponse.data?.comps
          ? getOrderedComps(saleCompsResponse.data?.comps, prop, CompType.Sales)
          : [];
        return {
            propertyDetails: getUpdatedPropertyResults?.data || propertyData,
            acquisitionComps: {
                isLoading: acquisitionCompsResponse.isLoading,
                error: acquisitionCompsResponse.error,
                comps: acqComps,
            },
            saleComps: {
                isLoading: saleCompsResponse.isLoading,
                error: saleCompsResponse.error,
                comps: saleComps,
            },
        };
    }, [
        propertyData,
        getUpdatedPropertyResults,
        acquisitionCompsResponse,
        saleCompsResponse,
    ]);

    useEffect(() => {
        const { isError, status, isSuccess } = putPropertyScoutFieldsResult;
        if (isError) {
            handleBackendError('Put Scout Fields', putPropertyScoutFieldsResult);
        } else if (status === 'fulfilled' && isSuccess) {
            toastSuccess('Property Updated');
            getUpdatedProperty(params.id);
        }
    }, [putPropertyScoutFieldsResult]);

    useEffect(() => {
        const { isError, status, isSuccess } = putValidationFieldsResult;
        if (isError) {
            handleBackendError('Put Validation Fields', putValidationFieldsResult);
        } else if (status === 'fulfilled' && isSuccess) {
            toastSuccess('Property Updated');
            getUpdatedProperty(params.id);
        }
    }, [putValidationFieldsResult]);

    const renderError = () => {
        console.error('Error retrieving property', propertyError);
        return <p>Error retrieving property information</p>;
    };

    const scoutFieldsUpdate = (field, value) => {
        const scoutFields = scoutProperty.propertyDetails.PropertyScoutFields;
        const updatedFields = {
            ...scoutFields,
        };
        updatedFields[field] = value;
        putPropertyScoutFields({
            propertyId: params.id,
            scoutFields: updatedFields,
        });
    };

    const scoutValidationFieldsUpdate = (validationFields) => {
        putValidationFields({
            propertyId: params.id,
            validationFields,
        });
    };

    return (
        <Page>
            <div className={propertyIsLoading ? 'opacity-50' : 'opacity-100'}>
                {propertyIsLoading || !scoutProperty.propertyDetails ? (
                    <Loader />
                ) : propertyError ? (
                    renderError()
                ) : (
                    <div className="container-fluid pb-3 ps-0 ps-md-4 pe-2">
                        <div className="row">
                            <div className="col-12">
                                <ScoutHeader propertyDetails={scoutProperty.propertyDetails} />
                            </div>
                        </div>
                        <div className="col-12">
                            <ScoutTabs
                                property={scoutProperty}
                                onScoutFieldsUpdate={scoutFieldsUpdate}
                                onScoutValidationFieldsUpdate={scoutValidationFieldsUpdate}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Page>
    );
}

export default Scout;
