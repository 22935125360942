import { aroApiSlice } from './apiSlice';
import { getFormattedDate } from '../../../utilities/dateHelpers';
import { getBaseURL } from '../../../utilities/config';
import { store } from '../../store';
import {
    cleanComps,
    cleanGenericData,
    cleanValidationFields,
} from '../../../utilities/dataAdapters';

// To Do: use baseURL and remove full url from query
export const scoutApiSlice = aroApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProperty: builder.query({
            query: (propertyId) =>
                `${getBaseURL()}/scoutapi?property-id=${propertyId}`,
            transformResponse: (response) => {
                let validationFields = response?.PropertyScoutFields?.validation_fields;
                let generic_data = response?.PropertyScoutFields?.generic_data;

                return {
                    ...response,
                    PropertyScoutFields: {
                        ...response?.PropertyScoutFields,
                        validation_fields: cleanValidationFields(validationFields),
                        generic_data: cleanGenericData(generic_data),
                    },
                };
            },
            providesTags: ['ScoutProperty'],
        }),
        getSaleComps: builder.query({
            query: (propertyId) =>
                `${getBaseURL()}/scoutapi?property-id=${propertyId}&sale-comps=true`,
            transformResponse: (response) => {
                return {
                    ...response,
                    comps: cleanComps(response.comps),
                };
            },
        }),
        getAcquisitionComps: builder.query({
            query: (propertyId) =>
                `${getBaseURL()}/scoutapi?property-id=${propertyId}&acq-comps=true`,
            transformResponse: (response) => {
                return {
                    ...response,
                    comps: cleanComps(response.comps),
                };
            },
        }),
        putPropertyScoutFields: builder.mutation({
            query: ({ propertyId, scoutFields }) => {
                const userEmail = store.getState().auth.userEmail;
                return {
                    url: `${getBaseURL()}/scoutapi?property-id=${propertyId}`,
                    method: 'PUT',
                    body: {
                        FieldChangeHistory: {
                            property_id: propertyId,
                            timestamp: getFormattedDate(),
                            scout_id: userEmail,
                        },
                        PropertyScoutFields: scoutFields,
                    },
                };
            },
            invalidatesTags: ['ScoutProperty'],
        }),
        putPropertyValidationFields: builder.mutation({
            query: ({ propertyId, validationFields }) => {
                const userEmail = store.getState().auth.userEmail;
                return {
                    url: `${getBaseURL()}/scoutapi?property-id=${propertyId}`,
                    method: 'PUT',
                    body: {
                        FieldChangeHistory: {
                            property_id: propertyId,
                            timestamp: getFormattedDate(),
                            scout_id: userEmail,
                        },
                        PropertyScoutValidationFields: validationFields,
                    },
                };
            },
            invalidatesTags: ['ScoutProperty'],
        }),
    }),
});

export const {
    useGetPropertyQuery,
    useGetSaleCompsQuery,
    useGetAcquisitionCompsQuery,
    useLazyGetPropertyQuery,
    usePutPropertyScoutFieldsMutation,
    usePutPropertyValidationFieldsMutation,
} = scoutApiSlice;
