export const colors = {
    charcoal: '#3d3935',
    skyfall: '#71b2c9',
    skyfallDark: 'hsl(195.68, 44.9%, 50%)',
    goldwood: '#B9975B',
    goldwoodLight: 'hsl(38.3, 40.17%, 85%)',
    walnut: '#8b5b29',
    white: '#fff',

    aroGreen: '#81b234',
    aroRed: '#da2836',
    aroGray: '#b8c3c7',
    aroYellow: '#fdd835', //'#f6d55c'
    aroYellowDark: 'hsl(48.9, 98.04%, 45%)',

    reChartsTickGray: '#666',
};

export const ValidationFields = [
    // correspond to Viability fields shown on Scout Dashboard/Viability Tab
    {
        key: 'SewerInspection',
        label: 'Sewer Inspection',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'GeoTech',
        label: 'GeoTech',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'Grading',
        label: 'Site Grading, FFE',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'ArboristReport',
        label: 'Arborist Report',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'SurveyRisks',
        label: 'Survey Risks',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'UndergroundTank',
        label: 'Underground Tank',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'PowerAvailable',
        label: 'Power Available',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'WaterAvailable',
        label: 'Water Available',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'EnvironmentalArea',
        label: 'Environmental Area',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'Logistics',
        label: 'Logistics',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'StormwaterCheck',
        label: 'Stormwater Check',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'CraneAssessment',
        label: 'Crane Assessment',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'Easements',
        label: 'Easements',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'cityPointOfSale',
        label: 'City Point of Sale',
        hasCostEstimate: true,
        isPassFailField: true,
    },
    {
        key: 'generalNotes',
        label: 'General Notes',
        hasCostEstimate: false,
        isPassFailField: false,
    },
];

export const ValidationValues = {
    Pass: 'Pass',
    NoPass: 'No Pass',
    Concerns: 'Concerns',
    NA: 'NA',
};

export const ViabilityFields = {
    // Fields within a ValidationField
    Notes: 'notes',
    Pass: 'pass',
    CostEstimate: 'costEstimate',
};

export const PropertyInfo = {
    FutureHomeSize: 2938,
};
