import React from 'react';
import { colors } from '../constants';

const CloseIcon = ({ color = colors.charcoal, size = 40 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={`${size}px`}
        viewBox="0 -960 960 960"
        width={`${size}px`}
        fill={color}
    >
        <path d="m251.33-204.67-46.66-46.66L433.33-480 204.67-708.67l46.66-46.66L480-526.67l228.67-228.66 46.66 46.66L526.67-480l228.66 228.67-46.66 46.66L480-433.33 251.33-204.67Z" />
    </svg>
);

export default CloseIcon;
