import React from 'react';
import { colors } from '../constants';

const DownCaretIcon = ({ color = colors.charcoal, size = '24' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={`${size}px`}
        viewBox="0 -960 960 960"
        width={`${size}px`}
        fill={color}
    >
        <path d="M480-360 280-560h400L480-360Z" />
    </svg>
);

export default DownCaretIcon;
