import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        userEmail: ''
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUserEmail: (state, action) => {
            state.userEmail = action.payload;
        },
    }
});

export const { setToken, setUserEmail } = authSlice.actions;

export const selectAuthToken = (state) => state.auth.token;
export const selectUserEmail = (state) => state.auth.userEmail;

export default authSlice.reducer;
