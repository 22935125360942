import React from 'react';
import { doesLotFit, LotFits } from '../../utilities/scoutHelpers';
import { CheckIcon, CloseIcon, QuestionIcon } from '../../icons';
import './viabilityPill.css';

const ViabilityPill = ({ propertyDetails }) => {
    const lotFits = doesLotFit(propertyDetails);
    let tooltipInfo = 'Viability not known';
    let pillStyleClass = 'viabilityPillUnknown';
    let Icon = QuestionIcon;

    if (lotFits !== LotFits.unknown) {
        if (lotFits) {
            tooltipInfo = 'Lot Fits';
            pillStyleClass = 'viabilityPillOK';
            Icon = CheckIcon;
        } else {
            tooltipInfo =
        'Lot does not fit. ' +
        propertyDetails.lot_analysis.AH1.reasons.join('. ');
            pillStyleClass = 'viabilityPillFail';
            Icon = CloseIcon;
        }
    }

    return (
        <div
            className={`viabilityPill ${pillStyleClass}`}
            title={tooltipInfo}
            tabIndex="0"
        >
            <Icon color="#fff" />
        </div>
    );
};

export default ViabilityPill;
