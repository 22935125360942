import React from 'react';
import './loader.css';
const background = {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: '30%',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '999999',
};
  
const loadingContainer = {
    position: 'relative',
};

function Loader() {
    return (
        <div id="icon-background" style={background}>
            <div style={loadingContainer}>
                <div data-loader="logo-circle"></div>
            </div>
        </div>
    );
}

export default Loader;
