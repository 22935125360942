import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import ScoutButton from '../scoutButton';
import propertyImage from '../../img/home.png';
import './kanbanPropertyCard.css';

const ONE_MILLION = 1000000;

const NotFeasibleButton = ({ id, address, onClick }) => {
    const modalId = `confirmNotFeasible${id}`;
    return (
        <>
            <button
                className="btn py-1 px-1 me-1 mb-1 lh-1 fw-bold notFeasibleButton"
                data-bs-toggle="modal"
                data-bs-target={`#${modalId}`}
                title="Mark property as not feasible"
            >
        X
            </button>
            <div
                className="modal fade"
                id={modalId}
                tabIndex="-1"
                aria-labelledby="confirmNotFeasibleLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="confirmNotFeasibleLabel">
                Property Is Not Feasible
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="propAddress">{address.toLowerCase()}</div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                                onClick={onClick}
                            >
                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const KanbanPropertyCard = ({
    id,
    address,
    city,
    source,
    estimatedSpread,
    mlsHistory,
    index,
    handleNotFeasible,
}) => {
    const spreadInMillions =
    Math.round((estimatedSpread / ONE_MILLION) * 10) / 10;

    const listPriceInMillions = mlsHistory?.listing_price
        ? Math.round((mlsHistory?.listing_price / ONE_MILLION) * 10) / 10
        : null;

    const listingLabel = mlsHistory?.listing_status
        ? `List Price(${mlsHistory.listing_status})`
        : 'List Price';

    const onNotFeasible = () => handleNotFeasible(id);

    const renderDetail = (label, valueInMillions) => {
        return valueInMillions > 0 ? (
            <div className="d-flex gap-3 flex-grow-1 flex-wrap justify-content-between align-items-center">
                <div
                    style={{ minWidth: '115px', fontSize: '12px', lineHeight: '12px' }}
                >
                    {label}
                    <span className="fw-bold propEstimatedSpread">
            &nbsp;${valueInMillions}M
                    </span>
                </div>
            </div>
        ) : null;
    };

    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => (
                <div
                    id={id}
                    className={`px-1 pt-2 pb-1 rounded-3 ${
                        snapshot.isDragging ? 'kanbanPropCardDragging' : 'kanbanPropCard'
                    }`}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <div className="row">
                        <div
                            className="col-3 pt-0 pb-0 text-center"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <img src={propertyImage} width="50" />
                            <div className="fw-bold propSource">{source}</div>
                        </div>
                        <div
                            className="col-9 d-flex flex-column gap-1 pt-0 pr-1 pb-0 propInfo text-capitalize"
                            style={{ paddingLeft: 0 }}
                        >
                            <div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <div className="propAddress lh-1 fw-bolder">
                                            {address.toLowerCase()}
                                        </div>
                                        <div className="fst-italic">{city.toLowerCase()}</div>
                                    </div>
                                    <NotFeasibleButton
                                        id={id}
                                        address={address}
                                        onClick={onNotFeasible}
                                    />
                                </div>
                            </div>
                            <div className="mt-0 mt-xl-1 d-flex flex-lg-column flex-xl-row gap-2 gap-md-1 justify-content-between align-items-center align-items-md-start align-items-xl-center">
                                <ScoutButton propertyId={id} />
                                <div>
                                    {renderDetail('Est. Spread', spreadInMillions)}
                                    {!!listPriceInMillions &&
                    renderDetail(listingLabel, listPriceInMillions)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default KanbanPropertyCard;
