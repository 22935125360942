import { useEffect, useState } from 'react';
import { useLazyGetMapDataQuery } from '../store/slices/apiSlice/map-api-slice';
import { simpleObjectCompare } from '../utilities/objectHelpers';
import { MapQueryType } from '../utilities/mapHelpers';

let lastRequest = {};

function useGetMapProperties() {
    const [apiData, setApiData] = useState();
    const [query, setQuery] = useState();
    const [mapBounds, setMapBounds] = useState([]);
    const [mapProperties, setMapProperties] = useState();
    const [getMapData, getMapDataResults] = useLazyGetMapDataQuery();

    useEffect(() => {
        if (query) {
            // TODO: this last query saving/comparison may not be necessary
            // RTK query may be ignoring earlier responses for us
            // (this is what it looks like from test observations and logging)
            lastRequest = query;
            console.debug(
                'Going to get map data',
                query?.city || 'no city',
                query?.bounds?.minLat || 'no min lat',
                query?.bounds?.maxLat || 'no max lat'
            );
            getMapData(query);
        }
    }, [query]);

    useEffect(() => {
        const { isError, status, isSuccess, data, originalArgs } =
      getMapDataResults;
        if (isError) {
            console.log('Get Map Data error', isError);
            // TODO: not handling backend error because these often happen with a lot of zooming/panning
            //handleBackendError(getKanbanDataResults);
        } else if (status === 'fulfilled' && isSuccess) {
            console.debug('Map Data Results', getMapDataResults?.originalArgs);
            console.debug(
                '--- comparing',
                simpleObjectCompare(originalArgs, lastRequest),
                'last request:',
                lastRequest?.city || 'no city',
                lastRequest?.bounds?.minLat || 'no min lat',
                lastRequest?.bounds?.maxLat || 'no max lat'
            );
            if (simpleObjectCompare(originalArgs, lastRequest)) {
                setApiData(data);
            }
        }
    }, [getMapDataResults]);

    useEffect(() => {
        if (apiData == null) return;

        let method = apiData.method;

        if (method == 'queryCity') {
            let ve = apiData.view_extent;
            setMapBounds([
                [ve.min_lon, ve.min_lat],
                [ve.max_lon, ve.max_lat],
            ]);
        }
        setMapProperties(apiData.records);
    }, [apiData]);

    const queryByCity = (city, status) =>
        setQuery({
            type: MapQueryType.City,
            city,
            status,
        });

    const queryBySearch = (search, city) =>
        setQuery({
            type: MapQueryType.Search,
            search,
            city,
        });

    const queryByBounds = (bounds, city, status) =>
        setQuery({
            type: MapQueryType.Bounds,
            bounds,
            city,
            status,
        });

    return {
        queryByCity,
        queryBySearch,
        queryByBounds,
        /*isError, */
        mapBounds,
        mapProperties,
    };
}

export default useGetMapProperties;
