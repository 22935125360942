import React from 'react';
import { colors } from '../constants';

const QuestionIcon = ({ color = colors.charcoal, size = 40 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={`${size}px`}
        viewBox="0 -960 960 960"
        width="40px"
        fill={color}
    >
        <path d="M428.67-326.67q.66-75 15.83-108.83t59.5-73.83q41.67-37.34 63.83-67.84 22.17-30.5 22.17-66.5 0-43.66-29.17-72.66-29.16-29-81.5-29-51.66 0-79.16 30t-39.84 62L270-692.67q21.67-60.66 75.33-104Q399-840 479.33-840q101.67 0 156.5 56.5 54.84 56.5 54.84 135.83 0 48.67-20.84 86.5-20.83 37.84-66.16 81.5-49 47-59.17 71.84-10.17 24.83-10.83 81.16h-105ZM479.33-80Q449-80 427.5-101.5T406-153.33q0-30.34 21.5-51.84 21.5-21.5 51.83-21.5 30.34 0 51.84 21.5 21.5 21.5 21.5 51.84 0 30.33-21.5 51.83T479.33-80Z" />
    </svg>
);

export default QuestionIcon;
