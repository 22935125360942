import React, { useState } from 'react';

import EditableCurrency from '../../editableCurrency';
import {
    DashboardCardTitle,
    Dashboard_Not_Available_Label,
    convertValueForDashboardDisplay,
} from '..';
import { PropertyScoutFieldNames } from '../../scout/scout';
import './cards.css';

const MINIMAL_GOOD_SPREAD = 1500000;
const GOOD_SPREAD = 'goodSpreadStyle';
const SMALL_SPREAD = 'smallSpreadStyle';

const getListingPrice = (mlsHistory) => {
    const { listing_status, listing_price } = mlsHistory;
    return `${convertValueForDashboardDisplay(
        listing_price,
        Math.round,
        '$',
        ''
    )} (${listing_status})`;
};

const AroEconomicsCard = ({ property: { propertyDetails }, onUpdate }) => {
    const {
        PropertyAVM,
        avg_street_price,
        max_street_price,
        PropertyScoutFields,
        MLSHistory,
    } = propertyDetails;

    // To Do: TBD better approach might be to re query after update
    const [manualSalePrice, setManualSalePrice] = useState(
        PropertyScoutFields?.manual_sale_price
    );
    const [manualPurchasePrice, setManualPurchasePrice] = useState(
        PropertyScoutFields?.manual_purchase_price
    );

    const avgStreetPrice = convertValueForDashboardDisplay(
        avg_street_price,
        Math.round,
        '$',
        ''
    );
    const maxStreetPrice = convertValueForDashboardDisplay(
        max_street_price,
        Math.round,
        '$',
        ''
    );
    const estimatedValue = convertValueForDashboardDisplay(
        PropertyAVM.estimated_value,
        Math.round,
        '$',
        ''
    );
    const estimatedSalePrice = convertValueForDashboardDisplay(
        PropertyAVM.future_sale_price,
        Math.round,
        '$',
        ''
    );
    const projectedSpread = convertValueForDashboardDisplay(
        PropertyAVM.estimated_spread,
        Math.round,
        '$',
        ''
    );
    const confidenceScore = convertValueForDashboardDisplay(
        PropertyAVM.confidence_score,
        Math.round,
        '',
        '%'
    );

    let projectedSpreadStyle = null;
    if (projectedSpread !== Dashboard_Not_Available_Label) {
        projectedSpreadStyle =
      Number(PropertyAVM.estimated_spread) >= MINIMAL_GOOD_SPREAD
          ? GOOD_SPREAD
          : SMALL_SPREAD;
    }
    const valueTDWidth = '50%';

    const renderRow = (label, value, styleClasses = null) => {
        return (
            <tr>
                <td>{label}</td>
                <td style={{ width: valueTDWidth }}>
                    <span style={{ fontWeight: 500 }} className={styleClasses}>
                        {value}
                    </span>
                </td>
            </tr>
        );
    };

    const renderEditableRow = (label, initialValue, id, onSave) => {
        return (
            <tr>
                <td>
                    <label htmlFor={id}>{label}</label>
                </td>
                <td style={{ width: valueTDWidth }}>
                    <EditableCurrency
                        initialValue={initialValue}
                        placeHolder="Enter Price"
                        id={id}
                        onSave={onSave}
                    />
                </td>
            </tr>
        );
    };

    const savePurchasePrice = (value) => {
        setManualPurchasePrice(value);
        onUpdate(PropertyScoutFieldNames.ManualPurchasePrice, Number(value));
    };
    const saveSalePrice = (value) => {
        setManualSalePrice(value);
        onUpdate(PropertyScoutFieldNames.ManualSalePrice, Number(value));
    };

    return (
        <div>
            <DashboardCardTitle text="Aro Economics" />
            <table className="w-100">
                <tbody>
                    {renderRow('Est. Value', estimatedValue)}
                    {renderRow('Est. Sale Price', estimatedSalePrice)}
                    {renderRow('Street Avg Price', avgStreetPrice)}
                    {renderRow('Max Street Price', maxStreetPrice)}
                    {renderRow('List Price', getListingPrice(MLSHistory))}
                </tbody>
            </table>

            <table className="mt-2 w-100">
                <tbody>
                    {renderRow('Projected Spread', projectedSpread, projectedSpreadStyle)}
                    {renderRow('Confidence Score', confidenceScore)}
                </tbody>
            </table>
            <table className="mt-2 w-100" style={{ borderSpacing: 0 }}>
                <tbody>
                    {renderEditableRow(
                        'Manual Purchase Price',
                        manualPurchasePrice,
                        'manualpurchaseprice',
                        savePurchasePrice
                    )}
                    {renderEditableRow(
                        'Manual Sale Price',
                        manualSalePrice,
                        'manualsaleprice',
                        saveSalePrice
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default AroEconomicsCard;
