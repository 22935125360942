import { PropertyInfo } from '../constants';

export const LotFits = {
    true: true,
    false: false,
    unknown: null,
};

export const doesLotFit = (propertyDetails) => {
    const { lot_analysis } = propertyDetails;
    const hasLotFitsInfo =
    lot_analysis?.AH1 &&
    lot_analysis.AH1?.house_does_fit !== undefined &&
    lot_analysis.AH1?.house_does_fit !== null;
    return hasLotFitsInfo
        ? LotFits[lot_analysis.AH1.house_does_fit]
        : LotFits.unknown;
};

export const countGoodComps = (comps) =>
    comps.reduce((acc, comp) => (Number(comp.sale_price) > 0 ? acc + 1 : acc), 0);

export const computeAvgSalePrice = (comps) => {
    if (!comps?.length) {
        return null;
    }
    const goodComps = comps.filter((comp) => Number(comp.sale_price) > 0);
    const total = goodComps.reduce(
        (acc, comp) => acc + Number(comp.sale_price),
        0
    );
    return `$${Math.round(total / goodComps.length).toLocaleString()}`;
};

export const computeFAR = (lotSizeString) => {
    const lotSize = parseFloat(lotSizeString);
    if (!lotSize) {
        return 'NA';
    }
    let temp = PropertyInfo.FutureHomeSize / lotSize;
    temp *= 100.0;
    temp = temp.toFixed(1);

    return temp + ' %';
};

export const computeWeightedAvgSalePrice = (comps) => {
    const goodComps = comps.filter((comp) => Number(comp.sale_price) > 0);
    let weightedTotal = 0;
    let totalWeight = 0;
    for (const comp of goodComps) {
        weightedTotal += comp.weight * comp.sale_price;
        totalWeight += comp.weight;
    }
    const weightedAvg = Math.round(weightedTotal / totalWeight);
    return { value: weightedAvg, formatted: `$${weightedAvg.toLocaleString()}` };
};

export const formatStreetAddress = (address) => {
    const commaIndex = address.lastIndexOf(',');
    return commaIndex >= 0
        ? address.slice(0, commaIndex).toLowerCase()
        : address.toLowerCase();
};

export const computeAvgSFPrice = (comps) => {
    const goodComps = comps.filter((comp) => Number(comp.price_sf) > 0);
    const total = goodComps.reduce((acc, comp) => acc + Number(comp.price_sf), 0);
    return `$${Math.round(total / goodComps.length).toLocaleString()}`;
};
