export const STATUS_IN_REVIEW = 2;
export const STATUS_NOT_FEASIBLE = 3;
export const STATUS_DRAFTING_OFFER = 4;
export const STATUS_OFFER_MADE = 5;
export const STATUS_OFFER_ACCEPTED = 7;

export function transformResponseToPropsByStatus(response) {
    const properties = response.records;
    const propsByStatus = {
        [STATUS_IN_REVIEW]: [],
        [STATUS_DRAFTING_OFFER]: [],
        [STATUS_OFFER_MADE]: [],
        [STATUS_OFFER_ACCEPTED]: [],
    };
    for (let status in propsByStatus) {
        const props = properties.filter((prop) => prop.status == Number(status));
        propsByStatus[status] = props;
    }
    return propsByStatus;
}
