import React from 'react';

import ComparablesCard from './components/comparablesCard';
import './scoutComparables.css';

const ScoutComparables = ({
    property: { propertyDetails, acquisitionComps, saleComps },
    onScoutFieldsUpdate,
}) => {
 
    return (
        <div>
            <ComparablesCard
                isLoading={acquisitionComps.isLoading}
                error={acquisitionComps.error}
                title="Acquisition Comparables"
                comps={acquisitionComps.comps}
                keyId="acq"
                property={propertyDetails}
                onUpdate={onScoutFieldsUpdate}
            />
            <ComparablesCard
                isLoading={saleComps.isLoading}
                error={saleComps.error}
                title="Sales Comparables"
                comps={saleComps.comps}
                keyId="sales"
                property={propertyDetails}
                onUpdate={onScoutFieldsUpdate}
            />
        </div>
    );
};

export default ScoutComparables;
