import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import { useAppSelector } from '../../store/hooks';
import { selectSources, selectStatuses } from '../../store/slices/mapSlice';
import KanbanCard from '../kanbanCard';

function KanbanBoard({
    propertiesByStatus,
    mlsHistories,
    onChangeStatus,
    onChangeOrder,
    onNotFeasible,
}) {
    const statusesDictionary = useAppSelector(selectStatuses);
    const sourcesDictionary = useAppSelector(selectSources);

    const handleDragEnd = (result) => {
        const { source, destination, draggableId } = result;
        if (!destination) {
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
      destination.index === source.index
        ) {
            return;
        }

        const action = {
            source: { statusId: source.droppableId, index: source.index },
            destination: {
                statusId: destination.droppableId,
                index: destination.index,
            },
        };
        if (source.droppableId !== destination.droppableId) {
            onChangeStatus(draggableId, action);
        } else {
            onChangeOrder(draggableId, action);
        }
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <div className="row pe-2 flex-grow-1 mb-5">
                {Object.keys(propertiesByStatus).map((status, index) => (
                    <div
                        className="col-lg-3 flex-grow-1 mb-1"
                        key={`kanbancard-${index}`}
                    >
                        <KanbanCard
                            id={String(status)}
                            title={statusesDictionary[status]}
                            properties={propertiesByStatus[status]}
                            sources={sourcesDictionary}
                            onNotFeasible={onNotFeasible}
                            mlsHistories={mlsHistories}
                        />
                    </div>
                ))}
            </div>
        </DragDropContext>
    );
}

export default KanbanBoard;
