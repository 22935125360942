import React, { useMemo } from 'react';

import {
    computeWeightedAvgSalePrice,
    countGoodComps,
} from '../../../utilities/scoutHelpers';
import Loading from '../../loading';
import Pill, { PillBackgroundColors } from '../../pill';
import CompsChartSelector, {
    ChartType,
} from '../compCharts/compsChartSelector';
import { DashboardCardTitle, Dashboard_Not_Available_Label } from '..';

const AcquisitionCompsCard = ({ property }) => {
    const { isLoading, error, comps } = property.acquisitionComps;
    const avgSalePrice = useMemo(
        () => (comps?.length ? computeWeightedAvgSalePrice(comps) : null),
        [comps]
    );

    const renderCompValue = (value, label) => (
        <div className="w-100">
            <Pill label={value} background={PillBackgroundColors.LinenDark} />
            <div style={{ fontSize: '12px', textAlign: 'center' }}>{label}</div>
        </div>
    );

    const renderError = () => {
        console.log('Acquisition comps error', error);
        return <p>Error retrieving aquisition comps</p>;
    };

    const nComps = comps.length ? countGoodComps(comps) : 'none';

    return (
        <div className="w-100">
            <DashboardCardTitle text="Acquisition Comps" />
            <div className="w-100 d-flex">
                {isLoading ? (
                    <Loading />
                ) : error ? (
                    renderError()
                ) : (
                    <div className="w-100 d-flex" style={{ gap: '4px' }}>
                        <div style={{ width: '40%' }}>
                            {renderCompValue(
                                avgSalePrice?.formatted || Dashboard_Not_Available_Label,
                                'Avg Sale Price (Wt)'
                            )}
                            <div className="mt-2 d-flex gap-2">
                                {renderCompValue('??', 'Days on Market')}
                                {renderCompValue(nComps, '# of Comps')}
                            </div>
                        </div>
                        <div className="w-100 ps-2 position-relative">
                            {!!comps.length && (
                                <CompsChartSelector
                                    comps={comps}
                                    initialChartType={ChartType.Date}
                                    avgSalePrice={avgSalePrice.value}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AcquisitionCompsCard;
