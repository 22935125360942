import { ValidationValues } from '../constants';

const CompDistance = 2.0;

export const LotFits = {
    true: true,
    false: false,
    unknown: null,
};

export const cleanValidationFields = (fields) => {
    if (!fields) {
        return fields;
    }
    let generalNotes = fields?.generalNotes;
    if (typeof generalNotes !== 'string') {
        return fields;
    }
    return {
        ...fields,
        generalNotes: {
            costEstimate: undefined,
            pass: ValidationValues.NA,
            notes: generalNotes,
        },
    };
};

export const cleanGenericData = (genericData) => {
    const salesComps = genericData?.salesComps;
    const acqComps = genericData?.acqComps;
    if (!salesComps && !acqComps) {
        return { ...genericData };
    }

    // backwards compatibility: old style was order:[]
    // new style is comps:[{propertyId, weight}]
    const salesCompsOrder = salesComps?.order;
    const acqCompsOrder = acqComps?.order;
    if (!salesCompsOrder && !acqCompsOrder) {
        return { ...genericData };
    }
    console.log('Clean GenericData need to move order to object', genericData);
    const cleanSalesComps = salesCompsOrder.map((comp) => ({
        propertyId: comp,
        weight: null,
    }));
    const cleanAcqComps = acqCompsOrder.map((comp) => ({
        propertyId: comp,
        weight: null,
    }));
    const cleanData = {
        ...genericData,
        salesComps: { comps: cleanSalesComps },
        acqComps: { comps: cleanAcqComps },
    };
    console.log('--- returning', cleanData);
    return { ...cleanData };
};

export const cleanComps = (comps) => {
    return comps
        .filter((comp) => Number(comp?.distance) <= CompDistance)
        .map((comp) => {
            const maxSize = Math.max(
                Number(comp.building_sf),
                Number(comp.living_sf)
            );
            let saleDate = comp.sale_date;
            if (saleDate && saleDate.length === 10) {
                saleDate = `${saleDate}T00:00:00`;
            }
            return { ...comp, building_sf: maxSize, sale_date: saleDate };
        });
};
