import React from 'react';
import { colors } from '../../../constants';

import './compCharts.css';

export const ReferenceLineSalePriceLabel = (props) => {
    const { viewBox } = props;
    return (
        <text x={viewBox.x + 10} y={viewBox.y + 10} className="referenceLineText">
      Avg Sale Price
        </text>
    );
};

export const YAxisPriceTick = ({ x, y, payload }) => {
    const mil = Number(payload.value) / 1000000;
    const label = `${Math.round(mil * 10) / 10}M`;
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dx={-24}
                fill={colors.reChartsTickGray}
                style={{ fontSize: '12px' }}
            >
                {label}
            </text>
        </g>
    );
};
