import React from 'react';
import { colors } from '../../constants';
import ScoutButton from '../scoutButton';
import './mapPropertyCard.css';

const ONE_MILLION = 1000000;

const MapPropertyCard = ({ id, address, city, rank, estimatedSpread }) => {
    const spreadInMillions =
    Math.round((estimatedSpread / ONE_MILLION) * 10) / 10;
    const rankColor = rank <= 10 ? colors.goldwood : colors.skyfall;

    return (
        <div
            id={id}
            className="ps-3 pe-1 py-2 rounded-3 mapPropCard d-flex align-items-center"
        >
            <div className="col-2 col-md-3 d-flex justify-content-center p-0 h-100">
                <div
                    className="property-logo rounded-circle d-flex align-items-center justify-content-center"
                    style={{ backgroundColor: rankColor }}
                >
                    <div className="fw-bold text-white">{rank}</div>
                </div>
            </div>

            <div className="col-10 col-md-9 d-flex flex-column gap-1 mt-1 pt-0 pb-0 propInfo text-capitalize">
                <div>
                    <div className="propAddress lh-1 fw-bolder">
                        {address.toLowerCase()}
                    </div>
                    <div className="propAddress mt-1 lh-1 fst-italic">
                        {city.toLowerCase()}
                    </div>
                </div>
                <div className="mt-1 d-flex gap-2 align-items-center">
                    <ScoutButton propertyId={id} btnType="btn-secondary" compact={true} />
                    <div className="d-flex gap-3 flex-grow-1 flex-wrap justify-content-between align-items-center">
                        <div>
                            {estimatedSpread > 0 && (
                                <div className="d-flex">
                                    <div className="d-none">Est. Spread</div>
                                    <div className="fw-bold propEstimatedSpread">
                    ${spreadInMillions}M
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapPropertyCard;
