// Note PT 11/21/2023: this module is self-contained, and I'm not sure how a functional
// component would work with the map library, so I am going to leave it as a class

import locationIcon from '../../img/crosshairs-solid.svg';
let mapForlocation;

export default class SelfLocationControl {
    eventHandler() {
        let options;
        function drawAnimatedCircle(map, lat, lon) {
            var size = 500;
            if (map.getLayer('outerCircle')) map.removeLayer('outerCircle');
            const sourceObject = map.getSource('source_circle_500');
            if (sourceObject) map.removeSource(sourceObject.id);
            map.addSource('source_circle_500', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [lon, lat],
                            },
                        },
                    ],
                },
            });

            map.addLayer({
                id: 'outerCircle',
                type: 'circle',
                source: 'source_circle_500',
                paint: {
                    'circle-radius': {
                        stops: [
                            [0, 0],
                            [20, 15000],
                        ],
                        base: 2,
                    },
                    'circle-color': 'rgba(113, 178, 201, 1)',
                    'circle-opacity': 0.4,
                },
            });
            if (map.getLayer('points')) map.removeLayer('points');
            const sourceObjectInnerDot = map.getSource('points');
            if (sourceObjectInnerDot) map.removeSource(sourceObjectInnerDot.id);
            // implementation of CustomLayerInterface to draw a pulsing dot icon on the map
            // see https://maplibre.org/maplibre-gl-js-docs/api/properties/#customlayerinterface for more info
            var pulsingDot = {
                width: size,
                height: size,
                data: new Uint8Array(size * size * 4),

                // get rendering context for the map canvas when layer is added to the map
                onAdd: function () {
                    var canvas = document.createElement('canvas');
                    canvas.width = this.width;
                    canvas.height = this.height;
                    this.context = canvas.getContext('2d');
                },

                // called once before every frame where the icon will be used
                render: function () {
                    var duration = 1000;
                    var t = (performance.now() % duration) / duration;

                    var radius = (size / 10) * 0.3;
                    var outerRadius = (size / 2) * 0.7 * t + radius;
                    var context = this.context;

                    // draw outer circle
                    context.clearRect(0, 0, this.width, this.height);
                    context.beginPath();
                    context.arc(
                        this.width / 2,
                        this.height / 2,
                        outerRadius,
                        0,
                        Math.PI * 2
                    );
                    context.fillStyle = 'rgba(113, 178, 201,' + (1 - t) + ')';
                    context.fill();

                    // draw inner circle
                    context.beginPath();
                    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
                    context.fillStyle = 'rgba(113, 178, 201, 1)';
                    context.strokeStyle = 'white';
                    context.lineWidth = 2 + 4 * (1 - t);
                    context.fill();
                    context.stroke();

                    // update this image's data with data from the canvas
                    this.data = context.getImageData(0, 0, this.width, this.height).data;

                    // continuously repaint the map, resulting in the smooth animation of the dot
                    map.triggerRepaint();

                    // return `true` to let the map know that the image was updated
                    return true;
                },
            };
            map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

            map.addSource('points', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [lon, lat],
                            },
                        },
                    ],
                },
            });
            map.addLayer({
                id: 'points',
                type: 'symbol',
                source: 'points',
                layout: {
                    'icon-image': 'pulsing-dot',
                },
            });
        }
        function success(pos) {
            const crd = pos.coords;
            const latitude = crd.latitude;
            const longitude = crd.longitude;
            mapForlocation.flyTo({
                center: [longitude, latitude],
                zoom: 16,
            });
            drawAnimatedCircle(mapForlocation, latitude, longitude);
        }

        function error(err) {
            console.warn('ERROR(' + err.code + '): ' + err.message);
        }

        options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        navigator.geolocation.getCurrentPosition(success, error, options);
    }
    onAdd(map) {
        this._map = map;
        mapForlocation = map;
        this._btn = document.createElement('button');
        this._btn_image = document.createElement('img');
        this._btn.type = 'button';
        this._btn.style.borderRadius = '20%';
        this._btn_image.className = 'locationIcon';
        this._btn_image.src = locationIcon;
        this._btn_image.style.width = '70%';
        this._btn_image.style.height = '70%';
        this._btn.onclick = this.eventHandler;
        this._btn.appendChild(this._btn_image);
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
        this._container.appendChild(this._btn);
        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}
